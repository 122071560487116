import React from "react";
import {
    Button,
    Dialog,
    DialogContent,
    DialogContentText,
} from "@mui/material";

import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt';

/**
 * Represents a confirmation dialog for verifying a word.
 * @param {Object} props - Component props.
 * @param {boolean} props.isOpen - Determines whether the dialog is open.
 * @param {function} props.onClose - Callback function to close the dialog.
 * @param {string | null} props.word - The word to verify.
 * @param {function} props.onThumbsUp - Callback function for a positive confirmation.
 * @param {function} props.onThumbsDown - Callback function for a negative confirmation.
 * @returns {JSX.Element} - JSX element representing the confirmation dialog.
 */
function ConfirmationDialog({isOpen, onClose, word, onThumbsUp, onThumbsDown}: {isOpen: boolean, onClose: () => void, word: string | null, onThumbsUp: () => void, onThumbsDown: () => void}) {
    return (
        <Dialog
            open={isOpen}
            keepMounted
            onClose={onClose}
            aria-describedby="alert-dialog-slide-description"
            PaperProps={{
                style: {
                    backgroundColor: 'rgba(255, 255, 255, 0.7)',
                    borderRadius: '1em'
                },
            }}
        >
            <DialogContent>
                <DialogContentText style={{color: 'black'}} id="alert-dialog-slide-description">
                    ¿La palabra "<strong>{word}</strong>" es correcta?
                </DialogContentText>
            </DialogContent>
            <div style={{padding: '0 24px 24px 24px', display: 'flex', justifyContent: 'center', gap:'1em'}}>
                <Button onClick={onThumbsUp} variant="contained" style={{backgroundColor: '#1769aa', borderRadius: '50%', width: '56px', height: '56px'}}>
                    <ThumbUpAltIcon style={{color: '#FFFFFF'}} />
                </Button>
                <Button onClick={onThumbsDown} variant="contained" style={{backgroundColor: '#1769aa', borderRadius: '50%', width: '56px', height: '56px'}}>
                    <ThumbDownAltIcon style={{color: '#FFFFFF'}} />
                </Button>
            </div>
        </Dialog>
    );
}

export default ConfirmationDialog;