import { useEffect } from 'react';

/**
 * Custom hook to periodically update progress using a callback function.
 * @param {function} getProgress - Callback function to get the current progress.
 * @param {function} setProgress - Function to set the progress.
 */
export const useUpdateProgress = (getProgress, setProgress) => {
    useEffect(() => {
        const intervalId = setInterval(() => {
            setProgress(getProgress());
        }, 1);
        return () => clearInterval(intervalId);
    }, [getProgress, setProgress]);
};
