import React from 'react';
import './styles.css';

const Button = ({ buttonImage, buttonText, handleOnClick }) => {
    return (
        <button
            className="google-button-container"
            onClick={() => handleOnClick()}
        >
            <img src={buttonImage}/>
            <span className="button-text">{buttonText}</span>
        </button>
    );
};

export default Button;