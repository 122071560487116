import React from "react";
import {
    Button,
    Dialog,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

/**
 * Represents a dialog for selecting a word from multiple alternatives.
 * @param {Object} props - Component props.
 * @param {boolean} props.isOpen - Determines whether the dialog is open.
 * @param {function} props.onClose - Callback function to close the dialog.
 * @param {string} props.word - The word with alternatives separated by a slash.
 * @param {function} props.onSelect - Callback function to handle the selected word.
 * @returns {JSX.Element} - JSX element representing the select word dialog.
 */
function SelectWordDialog({ isOpen, onClose, word, onSelect }: { isOpen: boolean, onClose: () => void, word: string, onSelect: (selectedWord: string) => void }) {

    const alternatives = word.split("/");

    return (
        <Dialog
            open={isOpen}
            keepMounted
            onClose={onClose}
            aria-describedby="select-word-dialog-description"
            PaperProps={{
                style: {
                    backgroundColor: 'rgba(255, 255, 255, 0.7)',
                    borderRadius: '1em'
                },
            }}
        >
            <DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    style={{
                        position: 'absolute',
                        right: 2,
                        top: 2,
                        color: 'grey',
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                    <DialogContentText style={{ color: 'black', textAlign: 'center', margin:'0.3rem'}} id="select-word-dialog-description">
                        ¿Cuál de estas palabras es correcta?
                    </DialogContentText>
                <div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', gap: '1rem' }}>
                    {alternatives.map((alt, index) => (
                        <Button
                            key={index}
                            variant="contained"
                            style={{
                                marginTop: '1em',
                                borderColor: '#1769aa',
                                color: '#ffffff',
                                backgroundColor: '#1769aa',
                                borderRadius: '1.5rem',
                                fontSize: '1em',
                            }}
                            onClick={() => onSelect(alt)}
                        >
                            {alt}
                        </Button>
                    ))}
                </div>
            </DialogContent>
        </Dialog>
    );
}

export default SelectWordDialog;
