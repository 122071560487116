import React from "react";

type AlertType = { word: string | null; severity: string | null; probability: number ; alphabet: boolean };

/**
 * Represents a bubble for displaying an alert message.
 * @param {Object} props - Component props.
 * @param {AlertType} props.alert - The alert message to display.
 * @returns {JSX.Element} - JSX element representing the alert bubble.
 */
function AlertBubble({alert}: {alert: AlertType}) {
    // Determine if the user is on a mobile device
    const isMobile = window.innerWidth <= 768;

    // Set font size based on whether the user is on a mobile device
    const fontSize = isMobile ? '1rem' : '1.3rem';

    return (
        <div>
            <span style={{fontSize, marginRight: '0.3em'}}>{alert.word}</span>
        </div>
    );
}

/**
 * Represents a bubble for displaying an alert message.
 * @param {Object} props - Component props.
 * @param {AlertType} props.sentence - The alert message to display.
 * @returns {JSX.Element} - JSX element representing the alert bubble.
 */
export function SentenceBubble({sentence}: {sentence}) {

    const isMobile = window.innerWidth <= 768;

    // Set font size based on whether the user is on a mobile device
    const fontSize = isMobile ? '1rem' : '1.3rem';

    return (
        <div className="bubble right" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', position: 'relative'}}>
            <span style={{fontSize : fontSize}}>{sentence}</span>
        </div>
    );
}

export default AlertBubble