import {useState} from "react";
import {generateSentence} from "../api/api.ts";

/**
 * Handle a "Thumbs Up" action for the current prediction.
 * @param {Object} currentPrediction - The current prediction object.
 * @param {function} setModalOpen - Function to set the modal open state.
 * @param {object} isModalOpenRef - Reference object for modal open state.
 * @param {function} setAlerts - Function to set the alerts.
 */
export const handleSelectWord = (currentPrediction, setModalOpen, isModalOpenRef, setAlerts) => {
    console.log("Thumbs Up for:", currentPrediction?.word);
    setModalOpen(false);
    isModalOpenRef.current = false;
    if (currentPrediction) {
        setAlerts((prevAlerts) => [...prevAlerts, {
            word: currentPrediction.word,
            severity: 'success',
            alphabet: false,
            probability: currentPrediction.probability
        }]);
    }
};

/**
 * Handle a "Thumbs Down" action for the current prediction.
 * @param {Object} currentPrediction - The current prediction object.
 * @param {function} setModalOpen - Function to set the modal open state.
 * @param {object} isModalOpenRef - Reference objec
 * t for modal open state.
 * @param {function} setAlerts - Function to set the alerts.
 */
export const handleCloseSelectWord = (currentPrediction, setModalOpen, isModalOpenRef, setAlerts) => {
    console.log("Thumbs Down for:", currentPrediction?.word);
    setModalOpen(false);
    isModalOpenRef.current = false;
    setAlerts(prevAlerts => [...prevAlerts]);
};

/**
 * Handle the deletion of a word from an alert based on the current detection state.
 * @param {number} index - The index of the alert to modify or delete.
 * @param {object} alphabetDetectionRef - Reference object to check if alphabet detection is active.
 * @param {function} setCurrentWord - Function to update the current word state.
 * @param {function} setAlerts - Function to update the alerts state.
 * @param {Array} alerts - The current list of alerts.
 */
export const handleDeleteAlertWord = (index: number, alphabetDetectionRef, setCurrentWord, setAlerts, alerts) => {
    if (alphabetDetectionRef.current) {
        updateAlertWhenAlphabetDetected(index, setCurrentWord, setAlerts, alerts);
    } else {
        removeAlertByIndex(index, setAlerts, alerts);
    }
};

/**
 * Update the alert's word when alphabet detection is active.
 * @param {number} index - The index of the alert to update.
 * @param {function} setCurrentWord - Function to update the current word state.
 * @param {function} setAlerts - Function to update the alerts state.
 * @param {Array} alerts - The current list of alerts.
 */
const updateAlertWhenAlphabetDetected = (index: number, setCurrentWord, setAlerts, alerts) => {
    const updatedAlerts = alerts.slice();
    const updatedWord = updatedAlerts[index].word?.slice(0, -1);
    if (updatedWord === "") {
        updatedAlerts.splice(index, 1);
    } else {
        updatedAlerts[index] = { ...updatedAlerts[index], word: updatedWord };
    }
    setAlerts(updatedAlerts);
    setCurrentWord(prev => prev.slice(0, -1));
};

/**
 * Remove an alert by index.
 * @param {number} index - The index of the alert to remove.
 * @param {function} setAlerts - Function to update the alerts state.
 * @param {Array} alerts - The current list of alerts.
 */
const removeAlertByIndex = (index: number, setAlerts, alerts) => {
    setAlerts(alerts.filter((_, i) => i !== index));
};

/**
 * Handle the click event to process alert words, generate a sentence, and reset states.
 * @param {Array} alerts - The current list of alerts.
 * @param {function} setSentences - Function to update the sentences state.
 * @param {function} setAlerts - Function to update the alerts state.
 * @param {function} resetAlphabet - Function to reset the alphabet state.
 */
export const handleClickWord = async (alerts,setSentences,setAlerts,resetAlphabet) => {
    const alertsWords = extractAlertWords(alerts);
    const sentence = await generateSentence(alertsWords);
    updateSentences(sentence, setSentences);
    setAlerts([]);
    resetAlphabet();
}

/**
 * Extract words from the alerts.
 * @param {Array} alerts - The current list of alerts.
 * @returns {Array} - The list of words extracted from alerts.
 */
const extractAlertWords = (alerts) => {
    return alerts.map(alert => alert.word);
};

/**
 * Update the sentences state with a new sentence.
 * @param {string} sentence - The new sentence to add.
 * @param {function} setSentences - Function to update the sentences state.
 */
const updateSentences = (sentence, setSentences) => {
    setSentences(oldArray => [...oldArray, sentence]);
};