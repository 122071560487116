import React from 'react';
import './styles.css';

const Loader = () => {
    return (
        <div className="loader-container">
            <img
                src="/dillo-brand.png"
                width='168'
            />
            <div className="loader"></div>
        </div>
    );
};

export default Loader;