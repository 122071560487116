import React, {useEffect, useState, useCallback, useRef} from "react";
import useLogic from "./hooks/index";
import {Alert, AlertColor, Button, LinearProgress, Snackbar,Chip,Box} from "@mui/material";
import {FRAMES_LENGTH_FOR_PREDICT} from "../../../constants";
import '../../App.css';
import ConfirmationDialog from "../../components/utils/confirmationDialog";
import  {SentenceBubble} from "../../components/utils/alert";
import ToggleSwitch from "../../components/utils/toggleSwitch";

import {toggleAlphabetDetection} from '../../components/effects-utils/alphabetHandlers';
import {
    handleSelectWord,
    handleCloseSelectWord,
    handleDeleteAlertWord, handleClickWord
} from '../../components/effects-utils/handlersSelectWordDialog.ts';
import {useResetWordOnAlphabetDetection} from '../../components/effects-utils/alphabetEffects';
import {useProcessAlertMessage} from '../../components/effects-utils/alertEffects';
import {useUpdateProgress} from '../../components/effects-utils/progressEffects';
import SendIcon from '@mui/icons-material/Send';
import StartView from "../StartView";
import SelectWordDialog from "../../components/utils/selectWordDialog.tsx";
import ProgressBar from "../../components/ProgressBar";
import WordComponent from "../../components/BubbleWord";
import MenuModal from "../../components/MenuModal";

type AlertType = { word: string | null; severity: string | null; probability: number; alphabet: boolean };

function HandsCapture() {
    const [isModalOpen, setModalOpen] = useState(false);
    const isModalOpenRef = useRef(false);
    const [showLandmarks, setShowLandmarks] = useState(false);//useState(window.innerWidth >= 1280);
    const [alphabetDetection, setAlphabetDetection] = useState(false);
    const currentFramesLengthForPredict = useRef(FRAMES_LENGTH_FOR_PREDICT);
    const [currentWord, setCurrentWord] = useState('');
    const alphabetDetectionRef = useRef(alphabetDetection);

    const [isSelectingWord, setIsSelectingWord] = useState(false);
    const isSelectingWordRef = useRef(false);

    useEffect(() => {
        alphabetDetectionRef.current = alphabetDetection;
    }, [alphabetDetection]);

    const [snackbarOpen, setSnackbarOpen] = useState({open: false, message: '', severity: 'success'});

    const handleCloseSnackbar = () => {
        setSnackbarOpen({...snackbarOpen, open: false});
    };

    const [loading, setLoading] = useState<boolean>(true)

    const {
        videoElement,
        maxVideoWidth,
        maxVideoHeight,
        canvasEl,
        getProgress,
        resetProgress,
        alertMessage,
    } = useLogic({
        isDialogOpen: isModalOpenRef,
        isSelectingWordOpen: isSelectingWordRef,
        alphabetDetection,
        showLandmarks,
        setSnackbar: setSnackbarOpen,
        setLoading
    });

    const [progress, setProgress] = useState(0);
    const [alerts, setAlerts] = useState<AlertType[]>([]);
    const [sentences, setSentences] = useState([]);
    const [currentPrediction, setCurrentPrediction] = useState<AlertType | null>(null);

    useResetWordOnAlphabetDetection(alphabetDetection, setCurrentWord);
    useProcessAlertMessage(alertMessage, isModalOpenRef, isSelectingWordRef, alphabetDetectionRef, setCurrentPrediction, setModalOpen, setCurrentWord, setAlerts);
    useUpdateProgress(getProgress, setProgress);

    const handleDeleteAlert = (index: number) => {
        handleDeleteAlertWord(index, alphabetDetectionRef, setCurrentWord, setAlerts, alerts);
    };

    const resetAlphabet = () => {
        setAlphabetDetection(false);
        setCurrentWord("");
    };

    // This function will be called when the button is clicked
    const handleClick = async () => {
        await handleClickWord(alerts, setSentences, setAlerts, resetAlphabet);
    };

    const handleWordSelect = (selectedWord) => {
        handleSelectWord({ ...currentPrediction, word: selectedWord }, setIsSelectingWord, isSelectingWordRef, setAlerts);
    };

    const handleClose = () => {
        handleCloseSelectWord(currentPrediction, setIsSelectingWord, isSelectingWordRef, setAlerts);
    }

    const isMobile = window.innerWidth <= 768;

    // Set font size based on whether the user is on a mobile device
    const leftM = isMobile ? '75%' : '88%';

    return (
        <div className="container">
            <MenuModal />
            {loading && <StartView/>}
            <video className="video" playsInline autoPlay ref={videoElement}/>
            <canvas className="canvas" id="glcanvas" ref={canvasEl} width={maxVideoWidth} height={maxVideoHeight}
                    style={{
                        display: 'block',
                        position: 'absolute',
                        minWidth: '100%',
                    }}/>
            <Snackbar open={snackbarOpen.open} autoHideDuration={2000} onClose={handleCloseSnackbar}
                      anchorOrigin={{vertical: 'top', horizontal: 'right'}}>
                <Alert onClose={handleCloseSnackbar} severity={snackbarOpen.severity as AlertColor}>
                    {snackbarOpen.message}
                </Alert>
            </Snackbar>
            {isSelectingWordRef.current ? (
                <SelectWordDialog
                    isOpen={isSelectingWordRef.current}
                    onClose={handleClose}
                    word={currentPrediction?.word}
                    onSelect={handleWordSelect}
                />
            ) : (
                <ConfirmationDialog
                    isOpen={isModalOpen}
                    onClose={() => {
                        setModalOpen(false);
                        resetProgress();
                    }}
                    word={currentPrediction?.word}
                    onThumbsUp={() => handleSelectWord(currentPrediction, setModalOpen, isModalOpenRef, setAlerts)}
                    onThumbsDown={() => handleCloseSelectWord(currentPrediction, setModalOpen, isModalOpenRef,setAlerts)}
                />
            )}
            {!loading && <WordComponent sentences={sentences} alerts={alerts} handleDeleteAlert={handleDeleteAlert} handleClick={handleClick} leftM={leftM} />}
            <ProgressBar progress={progress} currentFramesLengthForPredict={currentFramesLengthForPredict} />
            <div className={'control-toggle-container'}>
                <ToggleSwitch
                    label="Deletrear"
                    checked={alphabetDetection}
                    onChange={() => {
                        setAlphabetDetection(toggleAlphabetDetection(alphabetDetection, setAlerts, setCurrentWord, currentFramesLengthForPredict));
                        resetProgress();
                    }}
                />
            </div>
        </div>
    );
}

export default HandsCapture;