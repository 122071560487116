import React, { useState } from 'react';
import { useNavigate, useLocation  } from 'react-router-dom';
import PocketBase from 'pocketbase';
import Input from '../../components/Input';
import getDeviceType from '../../components/utils/getDeviceType';
import errorMessages from '../../components/utils/errorMessages';
import Button from '../../components/Button';
import'./styles.css'

const ConfirmPasswordReset = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const deviceType = getDeviceType()
    const [isError, setIsError] = useState('');
    const [password, setPassword] = useState('');
    const [passwordConfirm, setPasswordConfirm] = useState('');

    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get('token');
    console.log(token)
    const pb = new PocketBase('https://auth.dillo.ar/');

    const handleConfirmReset = async () => {
        try {
            await pb.collection('users').confirmPasswordReset(
                token,
                password,
                passwordConfirm
            );

            // Reautenticar al usuario
            await pb.collection('users').authWithPassword(
                pb.authStore.model.email,
                password
            );

            navigate('/');
        } catch (error) {
            setIsError(errorMessages[error.data.message] || 'Error al restablecer la contraseña');
        }
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            handleConfirmReset()
        }
    }

    return (
        <div className='reset-container'>
            <div className='logo-container'>
                <a href="/">
                    <img
                        src="/dillo-brand.png"
                        width='192px'
                        alt='Dillo'
                    />
                </a>
            </div>
            <h1 className="reset-title">Confirmar cambio de contraseña</h1>
            <div className='form-reset-container'>
            <div className={`${deviceType !== 'mobile' ? 'info-container-reset-desktop' : 'info-container-reset'}`}>
                    <Input
                        type='password'
                        name='password'
                        handleOnChange={(e) => setPassword(e.target.value)}
                        value={password}
                        label='Nueva contraseña'
                        isRequired handleKeyDown={handleKeyDown}/>
                    <Input
                        type='password'
                        name='passwordConfirm'
                        handleOnChange={(e) => setPasswordConfirm(e.target.value)}
                        value={passwordConfirm}
                        label='Confirmar nueva contraseña'
                        isRequired handleKeyDown={handleKeyDown}/>
                    <Button
                        handleOnClick={handleConfirmReset}
                        buttonText='Restablecer contraseña'
                    />
                    {isError && <label className="error-message">{isError}</label>}
                </div>
            </div>
        </div>
    );
};

export default ConfirmPasswordReset;
