const handleLetterPrediction = (letter: any, setSnackbar) => {
    switch (letter) {
        case 'No gesture recognized':
            setSnackbar({
                open: true,
                message: "No se ha podido reconocer, intentelo nuevamente",
                severity: "error"
            });
            return "";
        case 'V_2':
            return 'V';
        case 'W_2':
            return 'W';
        default:
            return letter;
    }
};

export default handleLetterPrediction;