import Loader from "../../components/Loader";

import './styles.css'
import React from "react";

const StartView = () => {

    return(
        <div  className={"element-container"} >
            <div className="button-container">
                <Loader />
            </div>
        </div>
    )
}

export default StartView