import { useEffect } from 'react';
import {MODAL_PROBABILITY_THRESHOLD} from "../../../constants";

/**
 * Handle modal opening for low probability alert message.
 * @param {Object} alertMessage - The alert message object.
 * @param {function} setCurrentPrediction - Function to set the current prediction.
 * @param {function} setModalOpen - Function to set the modal open state.
 * @param {object} isModalOpenRef - Reference object for modal open state.
 */
const handleModalOpenForLowProbability = (alertMessage, setCurrentPrediction, setModalOpen, isModalOpenRef) => {
    setCurrentPrediction(alertMessage);
    setModalOpen(true);
    isModalOpenRef.current = true;
};


/**
 * Handle turning off alphabet detection for an alert message.
 * @param {Object} alertMessage - The alert message object.
 * @param {function} setCurrentPrediction - Function to set the current prediction.
 * @param {function} setAlerts - Function to set the alerts.
 */
const handleAlphabetDetectionOff = (alertMessage, setCurrentPrediction, setAlerts) => {
    setCurrentPrediction(alertMessage);
    setAlerts((prevAlerts) => [
        ...prevAlerts.filter(alert => !alert.alphabet),
        {word: alertMessage.word, severity: 'success', alphabet: false, probability: alertMessage.probability}
    ]);
};

/**
 * Handle turning on alphabet detection for an alert message.
 * @param {Object} alertMessage - The alert message object.
 * @param {function} setCurrentWord - Function to set the current word.
 * @param {function} setCurrentPrediction - Function to set the current prediction.
 * @param {function} setAlerts - Function to set the alerts.
 */
const handleAlphabetDetectionOn = (alertMessage, setCurrentWord, setCurrentPrediction, setAlerts) => {
    setCurrentWord((prevWord) => {
        const newWord = prevWord + (alertMessage.word || '');
        setCurrentPrediction({
            word: newWord,
            severity: 'success',
            probability: alertMessage.probability,
            alphabet: true
        });
        setAlerts((prevAlerts) => [
            ...prevAlerts.filter(alert => !alert.alphabet),
            {word: newWord, severity: 'success', alphabet: true, probability: alertMessage.probability}
        ]);
        return newWord;
    });
};

/**
 * Handle modal opening for low probability alert message.
 */
const handleModalSelectWord = (alertMessage, isSelectingWordRef, setCurrentPrediction) => {
    setCurrentPrediction(alertMessage);
    isSelectingWordRef.current = true
};

/**
 * Custom hook to process alert messages and manage modal and alerts.
 * @param {Object} alertMessage - The alert message object.
 * @param {Object} isModalOpenRef - Reference object for modal open state.
 * @param {Object} isSelectingWordRef - Reference object for selecting word state.
 * @param {Object} alphabetDetectionRef - Reference object for alphabet detection state.
 * @param {Function} setCurrentPrediction - Function to set the current prediction.
 * @param {Function} setModalOpen - Function to set the modal open state.
 * @param {Function} setCurrentWord - Function to set the current word.
 * @param {Function} setAlerts - Function to set the alerts.
 */
export const useProcessAlertMessage = (alertMessage, isModalOpenRef, isSelectingWordRef, alphabetDetectionRef, setCurrentPrediction, setModalOpen, setCurrentWord, setAlerts) => {
    useEffect(() => {
        if (alertMessage && !isModalOpenRef.current && !isSelectingWordRef.current) {
            const words = alertMessage.word ? alertMessage.word.split("/") : [];

            if (words.length > 1) {
                handleModalSelectWord(alertMessage, isSelectingWordRef, setCurrentPrediction);
            } else {
                if (!alphabetDetectionRef.current) {
                    handleAlphabetDetectionOff(alertMessage, setCurrentPrediction, setAlerts);
                } else {
                    handleAlphabetDetectionOn(alertMessage, setCurrentWord, setCurrentPrediction, setAlerts);
                }
            }
        }
    }, [alertMessage, isModalOpenRef, isSelectingWordRef, alphabetDetectionRef, setCurrentPrediction, setModalOpen, setCurrentWord, setAlerts]);
};
