import React, { useState } from 'react';
import PocketBase from 'pocketbase';
import Input from '../../components/Input';
import'./styles.css'
import getDeviceType from '../../components/utils/getDeviceType';
import errorMessages from '../../components/utils/errorMessages';
import Button from '../../components/Button';


const RequestPasswordReset = () => {
    const deviceType = getDeviceType()
    const [email, setEmail] = useState('');
    const [isError, setIsError] = useState('');

    const pb = new PocketBase('https://auth.dillo.ar/');

    const handleRequestReset = async () => {
        try {
            await pb.collection('users').requestPasswordReset(email);
            alert('Se ha enviado un enlace de restablecimiento de contraseña a tu correo electrónico.');
        } catch (error) {
            setIsError(errorMessages[error.data.message] || 'Error al enviar el correo de restablecimiento');
        }
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            handleRequestReset()
        }
    }

    return (
    <div className='reset-container'>
        <div className='logo-container'>
            <a  href="/">
            <img
                src="/dillo-brand.png"
                width='192px'
                alt='Dillo'
            />
            </a>
        </div>
        <h1 className="reset-title">Restablecer contraseña</h1>
        <div className='form-reset-container'>
                <div className={`${deviceType !== 'mobile' ? 'info-container-reset-desktop' : 'info-container-reset'}`}>
                <Input
                    type='email'
                    name='email'
                    handleOnChange={(e) => setEmail(e.target.value)}
                    value={email}
                    label='Correo electrónico'
                    isRequired handleKeyDown={handleKeyDown}/>
                <Button
                    handleOnClick={handleRequestReset}
                    buttonText='Enviar correo'
                />
                {isError && <label className="error-message">{isError}</label>}
            </div>
            </div>
        </div>
    );
};

export default RequestPasswordReset;
