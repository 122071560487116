import {useEffect, useRef, useState} from 'react';
import {drawConnectors, drawLandmarks} from '@mediapipe/drawing_utils';
import {HAND_CONNECTIONS} from '@mediapipe/hands';
import predictValuesApi, {predictAlphabetApi} from "../../../components/api/api";
import {FRAMES_LENGTH_FOR_PREDICT, FRAMES_LENGTH_FOR_PREDICT_ALPHABET} from "../../../../constants";
//@ts-ignore
import {FilesetResolver, HandLandmarker} from '@mediapipe/tasks-vision';
import handleLetterPrediction from "../../../components/utils/handleLetterPrediction.tsx";

let recordedResults: any[] = [];

let lastPredictedWord = '';

interface AlertMessage {
    word: string | null;
    severity: string | null;
    probability: number;
}

interface UseLogicProps {
    isDialogOpen: React.MutableRefObject<boolean>;
    isSelectingWordOpen: React.MutableRefObject<boolean>;
    alphabetDetection: boolean;
    showLandmarks: boolean;
    setSnackbar: (snackbar: { open: boolean, message: string, severity: string }) => void;
    setLoading: (loading: boolean) => void;
}

let isProcessingRef = false

const triggerProcessing = () => {
    if (isProcessingRef) {
        setTimeout(() => {
            isProcessingRef = false;
            recordedResults = [];
        }, 400);
    }
};

function useLogic({
                      isDialogOpen,
                      isSelectingWordOpen,
                      alphabetDetection,
                      showLandmarks,
                      setSnackbar,
                      setLoading
                  }: UseLogicProps) {
    const videoElement = useRef<HTMLVideoElement | null>(null);
    const handLandmarker = useRef<HandLandmarker | null>(null);
    const canvasEl = useRef<HTMLCanvasElement | null>(null);
    const showLandmarksRef = useRef(showLandmarks);
    const alphabetDetectionRef = useRef(alphabetDetection);
    const [alertMessage, setAlertMessage] = useState<AlertMessage | null>(null);

    useEffect(() => {
        showLandmarksRef.current = showLandmarks;
        alphabetDetectionRef.current = alphabetDetection;
    }, [showLandmarks, alphabetDetection]);

    useEffect(() => {
        const initialize = async () => {
            setLoading(true);
            await createHandLandmarker();
            await initCamera();
        };

        initialize().then(r => setLoading(false));

        return () => {
            //@ts-ignore
            const tracks = videoElement.current?.srcObject?.getTracks();
            tracks?.forEach(track => track.stop());
            //@ts-ignore
            handLandmarker.current?.close();
        };
    }, []);

    const createHandLandmarker = async () => {
        try {
            const vision = await FilesetResolver.forVisionTasks(
                "https://cdn.jsdelivr.net/npm/@mediapipe/tasks-vision@0.10.14/wasm"
            );
            handLandmarker.current = await HandLandmarker.createFromOptions(vision, {
                baseOptions: {
                    modelAssetPath: `https://storage.googleapis.com/mediapipe-models/hand_landmarker/hand_landmarker/float16/latest/hand_landmarker.task`,
                    delegate: "GPU"
                },
                runningMode: "VIDEO",
                numHands: 2
            });
        } catch (error) {
            console.error('Failed to create HandLandmarker:', error);
        }
    };

    const initCamera = async () => {
        if (!videoElement.current || !handLandmarker.current) return;

        const constraintSets = [
            {
                video: {
                    width: { ideal: 640 },
                    height: { ideal: 360 },
                    frameRate: { ideal: 30 },
                },
            },
            {
                video: {
                    width: { ideal: 640 },
                    height: { ideal: 480 },
                    frameRate: { ideal: 30 },
                },
            },
            {
                video: {
                    width: { ideal: 1280 },
                    height: { ideal: 720 },
                    frameRate: { ideal: 30 },
                },
            },
            {
                video: {
                    width: { ideal: 800 },
                    height: { ideal: 600 },
                    frameRate: { ideal: 30 },
                },
            },
            {
                video: {
                    width: { ideal: 854 },
                    height: { ideal: 480 },
                    frameRate: { ideal: 30 },
                },
            },
            {
                video: true
            }
        ];

        for (const constraints of constraintSets) {
            try {
                videoElement.current.srcObject = await navigator.mediaDevices.getUserMedia(constraints);
                videoElement.current.addEventListener("loadeddata", predictWebcam);
                console.log('Camera initialized with constraints:', constraints);
                return; // If successful, exit the function
            } catch (error) {
                console.warn('Failed to initialize camera with constraints:', constraints, error);
                // Continue to the next set of constraints
            }
        }

        console.error('Failed to initialize camera with any of the provided constraints.');
    };

    async function processLandmarks(results: any) {
        if (isProcessingRef) return;

        const hasLandmarks = results.landmarks?.length;
        if (!hasLandmarks) return

        const isAlphabetDetectionOn = alphabetDetectionRef.current;
        try {
            let lengthVerify = isAlphabetDetectionOn ? FRAMES_LENGTH_FOR_PREDICT_ALPHABET : FRAMES_LENGTH_FOR_PREDICT;

            if (recordedResults.length < lengthVerify) {
                recordedResults = [...recordedResults, results];
            } else {
                isProcessingRef = true;
                const yPred = isAlphabetDetectionOn
                    ? await predictAlphabetApi(recordedResults)
                    : await predictValuesApi(recordedResults);

                console.log(yPred);

                if (yPred.letter) {
                    yPred.letter = handleLetterPrediction(yPred.letter, setSnackbar);
                }

                lastPredictedWord = isAlphabetDetectionOn ? yPred.letter : yPred.word;

                const alertMessage = {
                    word: isAlphabetDetectionOn ? yPred.letter : yPred.word,
                    severity: "success",
                    probability: yPred.probability,
                };

                setAlertMessage(alertMessage);
                recordedResults = []
                triggerProcessing();
            }
        } catch (e) {
            console.error('Prediction failed', e);

            const alertMessage = {
                word: "Failed",
                severity: "error",
                probability: null,
            };
            setAlertMessage(alertMessage);
        }
    }

    async function predictWebcam() {
        const canvas = canvasEl.current;
        if (!canvas || !videoElement.current || !handLandmarker.current) return;

        const ctx = canvas.getContext('2d');
        if (!ctx) return;

        const videoWidth = videoElement.current.videoWidth;
        const videoHeight = videoElement.current.videoHeight;

        canvas.style.width = videoWidth + "px";
        canvas.style.height = videoHeight + "px";
        canvas.width = videoWidth;
        canvas.height = videoHeight;

        const startTimeMs = performance.now();
        //@ts-ignore
        const results = await handLandmarker.current.detectForVideo(videoElement.current, startTimeMs);

        if (alphabetDetectionRef.current){
            // Create a temporary canvas to store the current video frame
            const tempCanvas = document.createElement('canvas');
            tempCanvas.width = videoWidth;
            tempCanvas.height = videoHeight;
            const tempCtx = tempCanvas.getContext('2d');
            if (tempCtx) {
                tempCtx.drawImage(videoElement.current, 0, 0, videoWidth, videoHeight);
                //@ts-ignore
                results.image = tempCanvas;
            }
        }

        ctx.save();
        ctx.clearRect(0, 0, canvas.width, canvas.height);

        if (results.landmarks) {
            for (const landmarks of results.landmarks) {
                drawConnectors(ctx, landmarks, HAND_CONNECTIONS, {
                    color: "white",
                    lineWidth: 1
                });
                drawLandmarks(ctx, landmarks, { color: "#7FCFF5", lineWidth: 1, radius: 4 });
            }
            // Store the frame as an image in results
            if (!isDialogOpen.current && !isSelectingWordOpen.current && !isProcessingRef) {
                await processLandmarks(results);
            }
        }

        ctx.restore();

        if (videoElement.current?.srcObject) {
            window.requestAnimationFrame(predictWebcam);
        }
    }

    return {
        maxVideoHeight: 360,
        maxVideoWidth: 640,
        canvasEl,
        videoElement,
        getProgress: () => recordedResults.length,
        resetProgress: () => {
            recordedResults = [];
        },
        alertMessage
    };
}


export default useLogic;
