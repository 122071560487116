import React from 'react';
import './styles.css';
import Chip from '@mui/material/Chip';
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import {Box} from "@mui/material";
import {SentenceBubble} from "../utils/alert.tsx";

const WordComponent = ({ sentences, alerts, handleDeleteAlert, handleClick, leftM }) => {
    return (
        <div className="alerts">
            {sentences.map((sentence, index) => (
                <SentenceBubble key={index} sentence={sentence}/>
            ))}
            <div className="bubble right" >
                <Box display="flex" flexWrap="wrap" gap={1}>
                    {alerts.map((alert, index) => (
                        <Chip className="word-chip"
                            key={index}
                            label={alert.word}
                            onDelete={() => handleDeleteAlert(index)}
                            style={{fontSize:'1em'}}
                        />
                    ))}
                </Box>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleClick}
                    centerRipple
                    style={{position: 'absolute', left: leftM}}
                >
                    <SendIcon/>
                </Button>
            </div>
        </div>
    );
};

export default WordComponent;
