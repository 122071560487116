import React from 'react';
import './styles.css';

const ProgressBar = ({ progress, currentFramesLengthForPredict }) => {
    return (
        <div className="progress-container">
            <div className="progress-bar"
                 style={{ width: `${(progress / currentFramesLengthForPredict.current) * 100}%` }}>
            </div>
        </div>
    );
};

export default ProgressBar;