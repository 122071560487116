import React from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import 'dayjs/locale/es';

const DatePickerComponent = ({ name, value, onChange, label, isError, handleKeyDown, isRequired=false }) => {
    const handleDateChange = (date) => {
        onChange({
            target: {
                name: 'birthdate',
                value: dayjs(date).format('YYYY-MM-DD'),
            },
        });
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
            <DatePicker
                name={name}
                label={
                    <>
                        {label}
                        {isRequired && <span> *</span>}
                    </>
                }
                value={dayjs(value)}
                onChange={handleDateChange}
                format="YYYY-MM-DD"
                slotProps={{
                    textField: {
                        error: isError,
                        helperText: isError ? 'Fecha es requerida' : '',
                        onKeyDown: handleKeyDown,
                        fullWidth: true,
                    }
                }}
            />
        </LocalizationProvider>
    );
};

export default DatePickerComponent;
